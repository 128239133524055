import React, {
    useEffect,
    useRef,
    useState
} from 'react'
import './ForexReport.css'
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import CloseMenuListIcon from '../../assets/closeMenuListIcon.png'
import Whiteloading from '../../assets/whiteloading.png'
import ForexDownIcon from '../../assets/forexDownIcon.png'
import CheckClickForListIcon from '../../assets/checkClickForLIST.png'
import LeftArrow from '../../assets/leftArrow.png'
import BackWard from '../../assets/backWard.png'
import Forward from '../../assets/forward.png'
import RightArrow from '../../assets/rightArrow.png'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BASE_URL, post } from '../Services/Calls';
import moment from 'moment';


interface IProps {
    openForexReportDialog: any;
    setOpenForexReportDialog: any;
    mobileView: any;
}


function ForexReport({
    openForexReportDialog,
    setOpenForexReportDialog,
    mobileView,
}: IProps) {
    const [switchLoader, setSwitchLoader] = useState<boolean>(false);
    const [startDate, setStartDate]: any = useState(null);
    const [endDate, setEndDate]: any = useState(null);
    const [forexDropDownShow, setForexDropDownShow] = useState(false);
    const [limitVal, setLimitVal] = useState("500");
    const [pageVal, setPageVal] = useState("1");
    const [pageArray, setPageArray] = useState([]);
    const [forexData, setForexData]:any = useState([]);
    const [forexItemColumnData, setForexItemColumnData] = useState( [
        {
            "item": "audcad.pro", checked:true
        },
        {
            "item": "audchf.pro", checked:false
        },
        {
            "item": "audjpy.pro", checked:false
        },
        {
            "item": "audnzd.pro", checked:false
        },
        {
            "item": "audusd.pro", checked:false
        },
        {
            "item": "cadchf.pro", checked:false
        },
        {
            "item": "cadjpy.pro", checked:false
        },
        {
            "item": "chfjpy.pro", checked:false
        },
        {
            "item": "euraud.pro", checked:false
        },
        {
            "item": "eurcad.pro", checked:false
        },
        {
            "item": "eurchf.pro", checked:false
        },
        {
            "item": "eurgbp.pro", checked:false
        },
        {
            "item": "eurjpy.pro", checked:false
        },
        {
            "item": "eurnzd.pro", checked:false
        },
        {
            "item": "eurusd.pro", checked:false
        },
        {
            "item": "gbpjpy.pro", checked:false
        },
        {
            "item": "gbpusd.pro", checked:false
        },
        {
            "item": "nzdcad.pro", checked:false
        },
        {
            "item": "nzdjpy.pro", checked:false
        },
        {
            "item": "nzdusd.pro", checked:false
        },
        {
            "item": "usdcad.pro", checked:false
        },
        {
            "item": "usdchf.pro", checked:false
        },
        {
            "item": "usdjpy.pro", checked:false
        }
    ]);

    const [groupColumnData, setGroupColumnData] = useState( [
        {
            "item": "All rows and groups", count:0
        },
        {
            "item": "Only groups 2+", count:2
        },
        {
            "item": "Only groups 3+", count:3
        },
        {
            "item": "Only groups 4+", count:4
        },
        {
            "item": "Only groups 5+", count:5
        },
        {
            "item": "Only groups 6+", count:6
        },
        {
            "item": "Only groups 7+", count:7
        },
        {
            "item": "Only groups 8+", count:8
        },
        {
            "item": "Only groups 9+", count:9
        },
        {
            "item": "Only groups 10+", count:10
        },
        {
            "item": "Only groups 11+", count:11
        },
        {
            "item": "Only groups 12+", count:12
        },        
    ]);
    const [clickForList, setClickForList]:any = useState({
        "item": "audcad.pro", checked:true
    });
    const [groupsDropVal, setGroupsDropVal]:any = useState({
        "item": "All rows and groups", count:0
    });
    const [clickForListShow, setClickForListShow] = useState(false);
    const [groupsDropShow, setGroupsDropShow] = useState(false);
    const [reportDropShow, setReportDropShow] = useState(false);
    const [reportStatus, setReportStatus]:any = useState('');
    const [totalProfit, setTotalProfit] = useState(0);
    const navigate = useNavigate();

    const [columns, setcolumns] = useState([
        { "id": 1, "columnName": "Ticket", isShow: true },
        { "id": 2, "columnName": "OpenTime", isShow: true },
        { "id": 3, "columnName": "Type", isShow: true },
        { "id": 4, "columnName": "Size", isShow: true },
        { "id": 5, "columnName": "Item", isShow: true },
        { "id": 6, "columnName": "Price", isShow: true },
        /* { "id": 7, "columnName": "S/L", isShow: true }, */
        { "id": 8, "columnName": "T/P", isShow: true },
        { "id": 9, "columnName": "Close Time", isShow: true },
        { "id": 10, "columnName": "Price", isShow: true },
        /* { "id": 11, "columnName": "Commission", isShow: true }, */
        { "id": 12, "columnName": "ID", isShow: true },
        /* { "id": 13, "columnName": "Taxes", isShow: true }, */
        /* { "id": 14, "columnName": "Swap", isShow: true }, */
        { "id": 15, "columnName": "Profit", isShow: true },
        { "id": 16, "columnName": "Tsize", isShow: true },  
        { "id": 17, "columnName": "Tprofit", isShow: true },
        { "id": 18, "columnName": "Ttrades", isShow: true }
    ]);
    useEffect(() => {
        var d = new Date('2023-05-01');
        //d.setMonth(d.getMonth() - 1);
        let tempStartDate = moment(d).format('MM/DD/yyyy')
        setStartDate(tempStartDate);
        let tempEndDate = moment(Date()).format('MM/DD/yyyy')
        setEndDate(tempEndDate);
        setLimitVal("500")
        setPageVal("1")
        getForexData(tempStartDate, tempEndDate, "500", "1", forexItemColumnData)
        getforexitemcolumndata()
        console.log("moment",);
    }, []);
    const getForexData = async (
        sDate,
        eDate,
        limit,
        pageNum, itemColumn
    ) => {
        setSwitchLoader(true);
        let sdateArray = sDate.split('/')
        let edateArray = eDate.split('/')
        let config = {
            fromDate: sdateArray[2] + "-" + sdateArray[0] + "-" + sdateArray[1],
            toDate: edateArray[2] + "-" + edateArray[0] + "-" + edateArray[1],
            limit: limit,
            page: pageNum,
            itemColumn:itemColumn
        }
        try {
            const apiData = await post(BASE_URL + '/getforexdatanew', config)
            console.log('apiData', apiData)
            if (apiData.data.status == 'success' && apiData.data.data && apiData.data.data.length > 0) {
                let totalRow: any = apiData.data.totalRow / limit;
                let totalRowMod: any = apiData.data.totalRow % limit;
                /* setForexData(apiData.data.data); */
                setData(apiData.data.data)


                if (totalRowMod > 0) {
                    totalRow = parseInt(totalRow) + 1
                }
                console.log("totalRow", totalRow)
                let p: any = [];
                for (let i = 1; i <= totalRow; i++) {
                    p.push(i);
                }
                setPageArray(p);
                setTotalProfit(apiData.data.totalProfit)
                setSwitchLoader(false);
            }
            else {
                setTotalProfit(0)
                setPageArray([]);
                setSwitchLoader(false);
                setForexData([])
            }
        } catch (ex) {
            setSwitchLoader(false);
            console.log('response9211', ex)
        }
    }

    function checkDisplay(index) {
        let status = false;
        for (let i = 0; i < columns.length; i++) {
            if (i == index)
                status = columns[i].isShow;
        }
        return status;
    }

    function collapseRow(indexes, index) {
        let status = false;
        let tempData:any=[...forexData];
        let indexesArr:any=indexes.split(',');
        for (let i = 0; i < indexesArr.length; i++) {
            
            if(tempData[indexesArr[i]].hide)
                {
                    tempData[indexesArr[i]].hide=false
                }
                else{
                    tempData[indexesArr[i]].hide=true
                }
        }
        if(tempData[index].collapseData)
            {
        tempData[index].collapseData=false
            }
            else{
                tempData[index].collapseData=true
            }
        setForexData([...tempData]);
    }
    
    function setData(data)
    {
        let newData:any=[];
        let preBorder=false;
        let tradeCount=1;
        let size=0;

        let profit=0;
        let collapseIndex="";
        let profitMinusTradeCount=0;
        for (let i = 0; i < data.length; i++) {
            
            //newData.push(data[i]);
            if(i!=0)
                {
                    let id=data[i].Fed.replace('TheFED-','').replace('[tp]','').replace('TheFed-','');
                    let idArray=id.split('-');
                    let previousId=data[i-1].Fed.replace('TheFED-','').replace('[tp]','').replace('TheFed-','');
                    let previousIdArray=previousId.split('-');
                    if(idArray[0]>0 && previousIdArray[0]==0)
                        {
                            data[i-1].isBorderTop=true;
                            preBorder= true;                           
                            size=size+parseFloat(data[i-1].Size);
                            profit=profit+parseFloat(data[i-1].Profit);
                            if(data[i-1].Profit<0)
                                {
                                    profitMinusTradeCount=profitMinusTradeCount+1;
                                }
                                
                        }
                        if(data.length-1 != i && preBorder)
                            {
                                
                                data[i].isBorderLeftRight=true;
                                tradeCount=tradeCount+1;
                                size=size+parseFloat(data[i].Size);
                                profit=profit+parseFloat(data[i].Profit);
                                if(data[i].Profit<0)
                                    {
                                        profitMinusTradeCount=profitMinusTradeCount+1;
                                    }
                                
                                let nextId=data[i+1].Fed.replace('TheFED-','').replace('[tp]','').replace('TheFed-','');
                                let nextIdArray=nextId.split('-');
                                if(idArray[0]>0 && nextIdArray[0]==0)
                                    {
                                        if(collapseIndex!="")
                                            {
                                                collapseIndex=collapseIndex+","+i;
                                            }
                                            else{
                                                collapseIndex=i.toString();
                                            }
                                        data[i].isBorderBottom=true;
                                        /* data[i].tradeCount=tradeCount;
                                        data[i].groupSize=size.toFixed(2);
                                        data[i].groupProfit=profit.toFixed(2);
                                        data[i].plus="collapse"; */
                                        data[i].collapseIndex=collapseIndex;
                                        data[i].collapseData=true;
                                        data[i].hide=true;

                                        data[i].ShowTradeCount=tradeCount;
                                        let collapseIndexArr:any=collapseIndex.split(',')
                                        for(let k=0;k<collapseIndexArr.length;k++)
                                            {
                                                console.log("45",collapseIndexArr[k])
                                                if(collapseIndexArr[k])
                                                    {
                                                data[collapseIndexArr[k]].ShowTradeCount=tradeCount;
                                                    }
                                                /* data[collapseIndexArr[k]].ShowTradeCount=tradeCount; */
                                            }
                                            if(collapseIndexArr[0])
                                                {
                                                    data[collapseIndexArr[0]-1].ShowTradeCount=tradeCount;
                                                    data[collapseIndexArr[0]-1].tradeCount=tradeCount;
                                                    data[collapseIndexArr[0]-1].profitMinusTradeCount=profitMinusTradeCount;
                                                    data[collapseIndexArr[0]-1].groupSize=size.toFixed(2);
                                                    data[collapseIndexArr[0]-1].groupProfit=profit.toFixed(2);
                                                    data[collapseIndexArr[0]-1].plus="collapse";
                                                    data[collapseIndexArr[0]-1].collapseIndex=collapseIndex;
                                                    data[collapseIndexArr[0]-1].collapseData=true;
                                                }

                                        preBorder=false;
                                        console.log("data45", profit.toFixed(2));
                                        tradeCount=1
                                        profitMinusTradeCount=0
                                        size=0
                                        profit=0
                                        collapseIndex="";
                                    }
                                    else{
                                        if(collapseIndex!="")
                                            {
                                                collapseIndex=collapseIndex+","+i;
                                            }
                                            else{
                                                collapseIndex=i.toString();
                                            }
                                        data[i].hide=true;
                                    }
                            }
                            else if(data.length-1 == i && preBorder){
                                data[i].isBorderLeftRight=true;
                                if(collapseIndex!="")
                                    {
                                        collapseIndex=collapseIndex+","+i;
                                    }
                                    else{
                                        collapseIndex=i.toString();
                                    }
                                        tradeCount=tradeCount+1;
                                        size=size+parseFloat(data[i].Size);
                                        profit=profit+parseFloat(data[i].Profit);
                                        data[i].isBorderBottom=true;
                                        if(data[i].Profit<0)
                                            {
                                                profitMinusTradeCount=profitMinusTradeCount+1;
                                            }
                                        /* data[i].tradeCount=tradeCount;
                                        data[i].groupSize=size.toFixed(2);
                                        data[i].groupProfit=profit.toFixed(2);
                                        data[i].plus="collapse"; */
                                        data[i].collapseIndex=collapseIndex;
                                        data[i].collapseData=true;


                                        data[i].ShowTradeCount=tradeCount;
                                        let collapseIndexArr:any=collapseIndex.split(',')
                                        for(let k=0;k<collapseIndexArr.length;k++)
                                            {
                                                console.log("45",collapseIndexArr[k])
                                                if(collapseIndexArr[k])
                                                    {
                                                data[collapseIndexArr[k]].ShowTradeCount=tradeCount;
                                                    }
                                                /* data[collapseIndexArr[k]].ShowTradeCount=tradeCount; */
                                            }
                                            if(collapseIndexArr[0])
                                                {
                                                    data[collapseIndexArr[0]-1].ShowTradeCount=tradeCount;
                                                    data[collapseIndexArr[0]-1].tradeCount=tradeCount;
                                                    data[collapseIndexArr[0]-1].profitMinusTradeCount=profitMinusTradeCount;
                                                    data[collapseIndexArr[0]-1].groupSize=size.toFixed(2);
                                                    data[collapseIndexArr[0]-1].groupProfit=profit.toFixed(2);
                                                    data[collapseIndexArr[0]-1].plus="collapse";
                                                    data[collapseIndexArr[0]-1].collapseIndex=collapseIndex;
                                                    data[collapseIndexArr[0]-1].collapseData=true;
                                                }

                                        preBorder=false;
                                        console.log("data45", profit.toFixed(2))
                                        tradeCount=1
                                        size=0
                                        profit=0
                                        collapseIndex="";

                            }
                    
                }
        }
        console.log("data45", data)
        setForexData(data);
    }


    const getforexitemcolumndata = async () => {
        let config = {}
        try {
            const apiData = await post(BASE_URL + '/getforexitemcolumndata', config)
            console.log('apiData1122', apiData)
            if (apiData.data.status == 'success' && apiData.data.data && apiData.data.data.length > 0) {
                let tempApiData: any = apiData.data.data
                for (let i = 0; i < tempApiData.length; i++) {
                    if(i==0)
                        {
                    tempApiData[i].checked = true
                        }
                else
                {
                tempApiData[i].checked = false
                }
                }
                console.log('tempApiData', tempApiData)
                setForexItemColumnData(tempApiData)
            }
            else {
            }
        } catch (ex) {

        }
    }

    function checkUncheck(item) {
        let tempData: any = [...forexItemColumnData]
        for (let i = 0; i < tempData.length; i++) {
            if (tempData[i].item == item.item) {

        console.log("test45", item);
                if (tempData[i].checked) {
                    tempData[i].checked = false
                }
                else {
                    tempData[i].checked = true
                }
            }
            /* else{
                tempData[i].checked = false
            } */
        }
        console.log('tempData', tempData)
        setForexItemColumnData([...tempData])
        getForexData(startDate, endDate, limitVal, "1", tempData);
    }

    function checkUncheckAll(string) {
        let tempData: any = [...forexItemColumnData]
        for (let i = 0; i < tempData.length; i++) {
            if (string == 'uncheckAll') {
                tempData[i].checked = false
            }
            else {
                tempData[i].checked = true
            }
        }
        console.log('tempData', tempData)
        getForexData(startDate, endDate, limitVal, "1", tempData);
        setForexItemColumnData(tempData)
    }

    const onPageChange = (page) => {
        setPageVal(page)
        getForexData(startDate, endDate, limitVal, page, forexItemColumnData);
      };


    return (
        <div>
            <Dialog
                open={openForexReportDialog}
                onClose={() => { setOpenForexReportDialog(false) }}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >

                {switchLoader ? <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, background: 'rgba(0,0,0,0.6)', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 22 }}>
                        <img className="loading-spinner" style={{ width: mobileView ? '65px' : '80px', height: mobileView ? '65px' : '80px' }} src={Whiteloading} alt="" />
                        {/* <div style={{ fontSize: 16, fontWeight: 600, color: 'darkgray' }}>Switching Account...</div> */}
                    </div>
                </div> : null}

                <ClickAwayListener onClickAway={() => { setOpenForexReportDialog(false) }}>
                    <div className='notificationDialogMainDiv' style={mobileView ? { padding: 0 } : { minWidth: '100%', maxWidth: '100%', maxHeight: '100%', minHeight: "100%", padding: 0 }} >
                        <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
                            <div style={{ width: "70%", textAlign: "left" }}>
                                <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Forex Report</h4></div>
                            <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <img style={{ width: 12, cursor: 'pointer' }} onClick={() => setOpenForexReportDialog(false)} src={CloseMenuListIcon} alt='' />
                            </div>
                        </div>
                        <div>

                        </div>


                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 5, flexDirection: 'row', marginRight: 50, zIndex: 2, position: "relative", padding: '0px 20px', marginBottom: 20 }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 20 }}>
                                <div>
                                    <div style={{ fontSize: 18, fontFamily: 'ManRopeSemiBold', marginBottom: 5 }}>Reports</div>
                                    <div className="clickForList" onClick={() => { setReportDropShow(!reportDropShow) }}>
                                        <span>{reportStatus == "" ? "Select Report" : reportStatus}</span>
                                        <div style={{ background: '#d3d3d3', width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3 }}>
                                            <img src={ForexDownIcon} style={{ width: 10, height: 10 }} alt="" />
                                        </div>
                                    </div>
                                    {reportDropShow &&
                                        <ClickAwayListener onClickAway={() => { setReportDropShow(false) }}>
                                            <div className="clickForListPopUpContainer" style={{ width: 180,height:'auto' }}>
                                                
                                                 <div style={{ fontSize: 16, fontFamily: 'ManRope', color: '#000', cursor: 'pointer',marginBottom:0,padding:'8px 10px' }} onClick={() => { setReportStatus(''); setGroupsDropVal({"item": "All rows and groups", count:0}); setReportDropShow(false) }}>
                                                        Select Report
                                                    </div>
                                                    <div style={{ fontSize: 16, fontFamily: 'ManRope', color: '#000', cursor: 'pointer',padding:'8px 10px' }} onClick={() => { setReportStatus('Profit'); setReportDropShow(false) }}>
                                                        Profit
                                                    </div>
                                                    <div style={{ fontSize: 16, fontFamily: 'ManRope', color: '#000', cursor: 'pointer',padding:'8px 10px' }} onClick={() => { setReportStatus('Group'); setReportDropShow(false) }}>
                                                        Group
                                                    </div>
                                            </div>
                                        </ClickAwayListener>
                                    }
                                </div>                               

                                <div>
                                    <div style={{ fontSize: 18, fontFamily: 'ManRopeSemiBold', marginBottom: 5 }}>Items</div>
                                    <div className="clickForList" onClick={() => { setClickForListShow(!clickForListShow) }}>
                                        <span>Click For List</span>
                                        {/* <span>{clickForList.item?clickForList.item.replace('.pro', ''):""}</span> */}
                                        <div style={{ background: '#d3d3d3', width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3 }}>
                                            <img src={ForexDownIcon} style={{ width: 10, height: 10 }} alt="" />
                                        </div>

                                    </div>
                                    {clickForListShow &&
                                        <ClickAwayListener onClickAway={() => { setClickForListShow(false) }}>
                                            <div className="clickForListPopUpContainer">
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, fontSize: 13, fontFamily: 'ManRopeSemiBold' }}>
                                                    <span style={{ cursor: 'pointer', borderBottom: '1px solid #3068c8' }} onClick={() => { checkUncheckAll('uncheckAll') }}>Uncheck all</span>
                                                    <span style={{ cursor: 'default', fontSize: 20 }}>|</span>
                                                    <span style={{ cursor: 'pointer', borderBottom: '1px solid #3068c8' }} onClick={() => { checkUncheckAll('checkAll') }}>Check all</span>
                                                </div>
                                                <div className="clickForListGridContainer">
                                                    {forexItemColumnData.map((item: any, index) => (
                                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 12, fontSize: 16, fontFamily: 'ManRope', color: '#000',width:'100%',cursor:'pointer',background:clickForList.item == item.item ? 'rgba(0,0,0,0.1)' : 'transparent',padding:'8px 10px' }} 
                                                        onClick={()=>{ checkUncheck(item);setClickForList(item);setClickForListShow(false)}}>
                                                            <div style={{ width: 15, height: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '0.5px solid #000', borderRadius: 3, cursor: 'pointer' }} /* onClick={() => { checkUncheck(item) }} */>
                                                                {item.checked &&
                                                                    <img src={CheckClickForListIcon} style={{ width: 15, height: 15 }} alt="" />
                                                                }
                                                            </div>
                                                            <span>{item.item?item.item.replace('.pro', ''):""}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </ClickAwayListener>
                                    }

                                </div>
                              {reportStatus=="Group"?  <div>
                                    <div style={{ fontSize: 18, fontFamily: 'ManRopeSemiBold', marginBottom: 5 }}>Groups</div>
                                    <div className="clickForList" onClick={() => { setGroupsDropShow(!groupsDropShow) }}>
                                        {/* <span>Click For List</span> */}
                                        <span>{groupsDropVal.item}</span>
                                        <div style={{ background: '#d3d3d3', width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3 }}>
                                            <img src={ForexDownIcon} style={{ width: 10, height: 10 }} alt="" />
                                        </div>

                                    </div>
                                    {groupsDropShow &&
                                        <ClickAwayListener onClickAway={() => { setGroupsDropShow(false) }}>
                                            <div className="clickForListPopUpContainer">
                                                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, fontSize: 18, fontFamily: 'ManRopeSemiBold' }}>
                                                    <span style={{ cursor: 'pointer', borderBottom: '1px solid #3068c8' }} onClick={() => { checkUncheckAll('uncheckAll') }}>Uncheck all</span>
                                                    <span style={{ cursor: 'default', fontSize: 30 }}>|</span>
                                                    <span style={{ cursor: 'pointer', borderBottom: '1px solid #3068c8' }} onClick={() => { checkUncheckAll('checkAll') }}>Check all</span>
                                                </div> */}
                                                <div className="clickForListGridContainer">
                                                    {groupColumnData.map((item: any, index) => (
                                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 12, fontSize: 16, fontFamily: 'ManRope', color: '#000',width:'100%',cursor:'pointer',background:clickForList.item == item.item ? 'rgba(0,0,0,0.1)' : 'transparent',padding:'8px 10px' }} onClick={()=>{ setGroupsDropVal(item);setGroupsDropShow(false)}}>
                                                            {/* <div style={{ width: 15, height: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '0.5px solid #000', borderRadius: 3, cursor: 'pointer' }} onClick={() => { checkUncheck(item) }}>
                                                                {item.checked &&
                                                                    <img src={CheckClickForListIcon} style={{ width: 15, height: 15 }} alt="" />
                                                                }
                                                            </div> */}
                                                            <span>{item.item?item.item.replace('.pro', ''):""}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </ClickAwayListener>
                                    }

                                </div>:null}
                                
                            </div>
                            <div>
                                <div style={{ fontSize: 18, fontFamily: 'ManRopeSemiBold', marginBottom: 5 }}>Date Ranges</div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 20, marginTop: 0 }}>
                                    <div>
                                        {/* <label style={{ fontSize: 15, fontFamily: 'ManRope' }}>Start Date: </label> <br /> */}
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => {
                                                let d = moment(date).format('MM/DD/yyyy');
                                                console.log("date", d)
                                                setStartDate(d)
                                                let tempEndDate = moment(Date()).format('MM/DD/yyyy')
                                                setEndDate(tempEndDate);
                                                /* setEndDate(null) */
                                                getForexData(d, tempEndDate, limitVal, pageVal, forexItemColumnData);
                                            }}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}
                                            maxDate={new Date()}
                                            placeholderText="From Date"
                                        />
                                    </div>
                                    <div>
                                        {/* <label style={{ fontSize: 15, fontFamily: 'ManRope' }}>End Date: </label> <br /> */}
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => {
                                                let d = moment(date).format('MM/DD/yyyy');
                                                console.log("date", d)
                                                setEndDate(d)
                                                setPageVal("1")
                                                getForexData(startDate, d, limitVal, "1", forexItemColumnData);
                                            }}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            placeholderText="To Date"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {reportStatus != "" && reportStatus!="Group" ?
                          <div style={{paddingLeft:20, fontSize:16, fontWeight:"bold", marginTop:-10, marginBottom:10}}>
                            Total Profit : {totalProfit && totalProfit>0?totalProfit.toFixed(2):0}</div>:null}
                        {/* <ClickAwayListener onClickAway={() => { setForexDropDownShow(false) }}>
                            <div style={{ padding: 20, paddingTop: 0, paddingBottom: 10 }}>
                                <div style={{ fontSize: 20, fontFamily: 'ManRopeSemiBold' }}>
                                    Columns
                                </div>
                                <div className="forexSelectContainer" onClick={() => { setForexDropDownShow(!forexDropDownShow) }}>
                                    <div style={{ display: 'flex', padding: '5px', justifyContent: 'flex-start', gap: 10, flexWrap: "wrap" }} >
                                        {columns.map((item: any, index) => (
                                            <div className="forexChipContainer" style={{ display: item.isShow ? "flex" : "none" }}>
                                                <div style={{ height: '100%', borderRight: '1px solid grey', paddingRight: 10 }}>{item.columnName}</div>
                                                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={CloseMenuListIcon} style={{ width: 10, cursor: 'pointer' }} alt=""
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation()
                                                            let data = [...columns];
                                                            if (data[index].isShow) {
                                                                data[index].isShow = false;
                                                            }
                                                            else {
                                                                data[index].isShow = true;
                                                            }
                                                            setcolumns([...data]);
                                                        }} />
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </div>
                                    <div className="forexDropIconDiv">
                                        <img src={ForexDownIcon} style={{ width: 20, height: 20 }} alt="" />
                                    </div>
                                </div>
                                {forexDropDownShow &&
                                    <div className="forexDropDownShowContainer">
                                        <div style={{ display: 'flex', padding: '5px', justifyContent: 'flex-start', gap: 10 }}>
                                            {columns.map((item: any, index) => (
                                                <div className="forexChipContainer" style={{ cursor: 'pointer', display: item.isShow ? "none" : "flex" }} onClick={() => {
                                                    let data = [...columns];
                                                    if (data[index].isShow) {
                                                        data[index].isShow = false;
                                                    }
                                                    else {
                                                        data[index].isShow = true;
                                                    }
                                                    setcolumns([...data]);
                                                }}>
                                                    <div style={{ height: '100%', }}>{item.columnName}</div>
                                                </div>
                                            ))
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </ClickAwayListener> */}


{pageArray && pageArray.length>0 &&
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
                            {/* <div style={{ padding: "0% 0px 0px  2%" }}>
                                Page : <select style={{ width: 40 }} name="page" value={pageVal} onChange={(event: any) => {
                                    console.log(event.target.value)
                                    setPageVal(event.target.value)
                                    getForexData(startDate, endDate, limitVal, event.target.value, forexItemColumnData);
                                }}>
                                    {pageArray.map((item: any, index) => (
                                        <option value={item}>{item}</option>
                                    ))
                                    }

                                </select>  of  {pageArray.length}

                            </div> */}
<div style={{textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center',padding: '10px 10px 10px 27px',/* border: '1px solid #e0e0e0' */}}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 5, fontSize: 13 }}>
        <div onClick={() => { if (parseInt(pageVal) > 1) {onPageChange(1)} }} style={{ marginRight: 21 }}>
            <img src={BackWard} style={{ width: 11, height: 11, opacity: parseInt(pageVal) > 1 ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
          <div onClick={() => { if (parseInt(pageVal) > 1) { onPageChange(parseInt(pageVal) - 1) } }} style={{ marginRight: 14 }}>
            <img src={LeftArrow} style={{ width: 9, height: 9, opacity: parseInt(pageVal) > 1 ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 5, fontSize: 14 }}>
            {parseInt(pageVal) > 2 &&
              <div className="pagingNumber" onClick={() => {onPageChange(parseInt(pageVal) - 2)}} style={{fontWeight:'bold'}}>
                ...
              </div>
            }
            {pageArray.map((number) => (
              <>
                {((number == parseInt(pageVal) || number == parseInt(pageVal) - 1 || number == parseInt(pageVal) + 1) || (parseInt(pageVal) == 1 && number == 3) || (parseInt(pageVal) == pageArray.length && number == pageArray.length - 2)) &&
                  <div key={number} className={pageVal == number ? "pagingNumberSelected" : "pagingNumber"} onClick={() => { onPageChange(number)}}>{number}</div>
                } 
              </>
            ))}
            {parseInt(pageVal) + 1 < pageArray.length &&
              <div className="pagingNumber" onClick={() => {onPageChange(parseInt(pageVal) + 2) }} style={{fontWeight:'bold'}}>
                ...
              </div>
            }
          </div>
          <div onClick={() => { if (pageArray.length != parseInt(pageVal)) { onPageChange(parseInt(pageVal) + 1) } }} style={{ marginLeft: 13.5 }}>
            <img src={RightArrow} style={{ width: 10, height: 10, opacity: pageArray.length != parseInt(pageVal) ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
          <div onClick={() => { if (pageArray.length != parseInt(pageVal)) { onPageChange(pageArray.length) } }} style={{ marginLeft: 19 }}>
            <img src={Forward} style={{ width: 12, height: 12, opacity: pageArray.length != parseInt(pageVal) ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
        </div>
      </div>


                            <div style={{ padding: "0% 2% 0%  0%" }}>
                                Count Per page :&nbsp; &nbsp;
                                <select name="limt" value={limitVal} onChange={(event: any) => {
                                    console.log(event.target.value)
                                    setLimitVal(event.target.value)
                                    setPageVal("1")
                                    getForexData(startDate, endDate, event.target.value, "1", forexItemColumnData);
                                }}>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                    <option value="1500">1500</option>
                                </select>
                            </div>
                        </div>

}
                        
                        
                        <div className="tableFixHead">
                            <table >
                                <thead style={{ background: '#bdbdbd' }}>
                                    <tr>
                                        {columns.map((item: any, index) => (
                                            <>
                                          {reportStatus=='Group' ?  <th style={item.isShow ? {} : { display: "none" }}>{item.columnName}</th>:
                                          reportStatus!='Group' && index<11?<th style={item.isShow ? {} : { display: "none" }}>{item.columnName}</th>:
                                          null}
                                            </>
                                        ))
                                        }

                                    </tr>
                                </thead>
                                <tbody>
                                    {forexData.map((item: any, index) => (
                                        <>
                                      {(reportStatus!='Group' || reportStatus=='Group') && groupsDropVal.count==0?  
                                      <tr className={reportStatus=='Group' && item.hide?'ishide':''} 
                                        style={item.isBorderTop && reportStatus=="Group"?{borderTop:"3px solid #000", 
                                            borderLeft:"3px solid #000", borderRight:"3px solid #000", borderBottom:item.collapseData?"3px solid #000":"none"}:
                                        item.isBorderBottom && reportStatus=="Group"?{borderBottom:"3px solid #000", borderLeft:"3px solid #000", borderRight:"3px solid #000"}:
                                        item.isBorderLeftRight && reportStatus=="Group"?{borderLeft:"3px solid #000", borderRight:"3px solid #000"}:{}}>
                                            <td /* style={checkDisplay(0) ? {} : { display: "none" }} */>
                                            {item.Ticket}                                            
                                            </td>
                                            <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.OpenTime}</td>
                                            <td /* style={checkDisplay(2) ? {} : { display: "none" }} */>{item.Type}</td>
                                            <td /* style={checkDisplay(3) ? {} : { display: "none" }} */>{item.Size}
                                            {/* {item.groupSize && reportStatus=="Group"? <div style={{fontWeight:"bolder", fontSize:18, marginTop:5}}>
                                                {item.groupSize}</div>:null} */}
                                            </td>
                                            <td /* style={checkDisplay(4) ? {} : { display: "none" }} */>{item.Item}</td>
                                            <td /* style={checkDisplay(5) ? {} : { display: "none" }} */>{item.Price}</td>
                                            {/* <td style={checkDisplay(6) ? {} : { display: "none" }}>{item.SL}</td> */}
                                            <td /* style={checkDisplay(7) ? {} : { display: "none" }} */>{item.TP}</td>
                                            <td /* style={checkDisplay(8) ? {} : { display: "none" }} */>{item.CloseTime}</td>
                                            <td /* style={checkDisplay(9) ? {} : { display: "none" }} */>{item.PriceNext}</td>
                                            {/* <td style={checkDisplay(10) ? {} : { display: "none" }}>{item.Commission}</td> */}
                                            <td /* style={checkDisplay(12) ? {} : { display: "none" }} */>
                                            {item.Fed.replace('TheFED-','').replace('[tp]','').replace('TheFed-','')}
                                            {/* {item.tradeCount && reportStatus=="Group"? <div style={{fontWeight:"bolder", fontSize:18, marginTop:5}}>
                                                {item.tradeCount}</div>:null} */}
                                            </td>
                                            {/* <td style={checkDisplay(11) ? {} : { display: "none" }}>{item.Taxes}</td> */}
                                            {/* <td style={checkDisplay(12) ? {} : { display: "none" }}>{item.Swap}</td> */}
                                            <td>{item.Profit}
                                            {/* {item.groupProfit && reportStatus=="Group"? <div style={{fontWeight:"bolder", fontSize:18}}>
                                                {item.groupProfit}</div>:null} */}
                                            </td>
                                            {reportStatus=='Group' ?   <>
                                            <td>{item.groupSize && reportStatus=="Group"? <div style={{fontWeight:"bolder", fontSize:18}}>
                                            {item.groupSize}</div>:null}</td>
                                            <td>{item.groupProfit && reportStatus=="Group"? <div style={{fontWeight:"bolder", fontSize:18}}>
                                            {item.groupProfit}</div>:null}</td>
                                            <td>{item.tradeCount && reportStatus=="Group"? <div style={{fontWeight:"bolder", fontSize:18}}>
                                            {item.tradeCount}<span style={{color:"blue", fontSize:16}}>({item.profitMinusTradeCount?item.profitMinusTradeCount:'0'})</span>
                                            {item.plus && reportStatus=="Group"? <span style={{fontWeight:"bolder", fontSize:18, cursor:"pointer", 
                                            marginLeft:15}} 
                                            onClick={() =>{collapseRow(item.collapseIndex, index)}}>{item.collapseData?'+':'-'}</span>:null}
                                            </div>:null}
                                            
                                            </td>
                                            </>:null}                                           
                                        </tr>
                                        :
                                        reportStatus=='Group' && item.ShowTradeCount && item.ShowTradeCount >= groupsDropVal.count?

                                        <tr className={item.hide?'ishide':''} 
                                        style={item.isBorderTop && reportStatus=="Group"?{borderTop:"3px solid #000", 
                                            borderLeft:"3px solid #000", borderRight:"3px solid #000", borderBottom:item.collapseData?"3px solid #000":"none"}:
                                        item.isBorderBottom && reportStatus=="Group"?{borderBottom:"3px solid #000", borderLeft:"3px solid #000", borderRight:"3px solid #000"}:
                                        item.isBorderLeftRight?{borderLeft:"3px solid #000", borderRight:"3px solid #000"}:{}}>
                                            <td /* style={checkDisplay(0) ? {} : { display: "none" }} */>
                                            {item.Ticket}                                            
                                            </td>
                                            <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.OpenTime}</td>
                                            <td /* style={checkDisplay(2) ? {} : { display: "none" }} */>{item.Type}</td>
                                            <td /* style={checkDisplay(3) ? {} : { display: "none" }} */>{item.Size}
                                            {/* {item.groupSize && reportStatus=="Group"? <div style={{fontWeight:"bolder", fontSize:18, marginTop:5}}>
                                                {item.groupSize}</div>:null} */}
                                            </td>
                                            <td /* style={checkDisplay(4) ? {} : { display: "none" }} */>{item.Item}</td>
                                            <td /* style={checkDisplay(5) ? {} : { display: "none" }} */>{item.Price}</td>
                                            {/* <td style={checkDisplay(6) ? {} : { display: "none" }}>{item.SL}</td> */}
                                            <td /* style={checkDisplay(7) ? {} : { display: "none" }} */>{item.TP}</td>
                                            <td /* style={checkDisplay(8) ? {} : { display: "none" }} */>{item.CloseTime}</td>
                                            <td /* style={checkDisplay(9) ? {} : { display: "none" }} */>{item.PriceNext}</td>
                                            {/* <td style={checkDisplay(10) ? {} : { display: "none" }}>{item.Commission}</td> */}
                                            <td /* style={checkDisplay(12) ? {} : { display: "none" }} */>
                                            {item.Fed.replace('TheFED-','').replace('[tp]','').replace('TheFed-','')}
                                            {/* {item.tradeCount && reportStatus=="Group"? <div style={{fontWeight:"bolder", fontSize:18, marginTop:5}}>
                                                {item.tradeCount}</div>:null} */}
                                            </td>
                                            {/* <td style={checkDisplay(11) ? {} : { display: "none" }}>{item.Taxes}</td> */}
                                            {/* <td style={checkDisplay(12) ? {} : { display: "none" }}>{item.Swap}</td> */}
                                            <td>{item.Profit}
                                            {/* {item.groupProfit && reportStatus=="Group"? <div style={{fontWeight:"bolder", fontSize:18}}>
                                                {item.groupProfit}</div>:null} */}
                                            </td>
                                            {reportStatus=='Group' ?   <>
                                            <td>{item.groupSize && reportStatus=="Group"? <div style={{fontWeight:"bolder", fontSize:18}}>
                                            {item.groupSize}</div>:null}</td>
                                            <td>{item.groupProfit && reportStatus=="Group"? <div style={{fontWeight:"bolder", fontSize:18}}>
                                            {item.groupProfit}</div>:null}</td>
                                            <td>{item.tradeCount && reportStatus=="Group"? <div style={{fontWeight:"bolder", fontSize:18}}>
                                            {item.tradeCount}<span style={{color:"blue", fontSize:16}}>({item.profitMinusTradeCount?item.profitMinusTradeCount:'0'})</span>
                                            {item.plus && reportStatus=="Group"? <span style={{fontWeight:"bolder", fontSize:18, cursor:"pointer", marginLeft:15}} 
                                            onClick={() =>{collapseRow(item.collapseIndex, index)}}>{item.collapseData?'+':'-'}</span>:null}
                                            </div>:null}
                                            
                                            </td>
                                            </>:null}                                            
                                        </tr>:null
                                        }
                                        </>
                                    ))
                                    }
                                </tbody>
                            </table>
                        </div>


                    </div>


                </ClickAwayListener>



            </Dialog>
        </div>
    )
}

export default ForexReport
