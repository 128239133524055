import axios from "axios";
import mixpanel from 'mixpanel-browser';


import CryptoJS from 'crypto-js'
// export const BASE_URL = "https://www.managestuff.ai/api";
// export const BASE_URL = "http://zho.mmm.mybluehost.me/apiv2";
export const BASE_URL = "https://www.managestuff.ai/apiv2";


const _config = {};

export const FEKY=process.env.REACT_APP_KEY_DEKY
export const DEKY=process.env.REACT_APP_KEY_DEKY

export const isProduction:any = 1;
export  function mixPanelInIt() {
  try{
  if(isProduction==1)
  {
  mixpanel.init("120fa1e81ccd740e36460894ed77149e", { debug: true/* , track_pageview: true */, persistence: 'localStorage' });
  /* mixpanel.track('Open site', {
    'Open site': 'Open site'
  }) */
  }
}
catch(ex){

}//ONLY_FULL_GROUP_BY
}
export  function mixPanelTrack(eventName,data) {
  try{
  if(isProduction==1)
  {
  mixpanel.track(eventName, data)
  }
}
catch(ex){
  
}
}


export  function encData(text: string) {
  let response = text;
if(text!="")
{
  response = CryptoJS.AES.encrypt(text, DEKY).toString();
    // console.log("CryptoJS11", response)
}
  return response;
}
export  function decData(text: string, isEnc:string) {
  let response = text;
  try
  {
if(isEnc=="1" && text!="")
{
  var bytes = CryptoJS.AES.decrypt(text, DEKY);
  // console.log("CryptoJS45", text)
  response = bytes.toString(CryptoJS.enc.Utf8);
}
  }
  catch(ex)
  {
    response = text;
  }
    //  console.log("CryptoJS45", response)
  return response;
}
export async function postWithOutToken(url: string, data: any) {
    const response = await axios.post(url, data,);
    return response;
  }

  export async function post(url: string, data: any) {
    if (
      localStorage.getItem("token") !== null &&
      localStorage.getItem("token") !== undefined
    ) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
    }
    const response : any = await axios.post(url, data);
    //console.log("res11", response)
    if(response && response.data && response.data.statusCode && response.data.statusCode=="401")
    {
      var myCookie = localStorage.getItem("setCookie");
      var Intro = localStorage.getItem("intro");
      localStorage.clear();
      if (myCookie != null) {
        localStorage.setItem("setCookie", myCookie);
      }
      if (Intro != null) {
      localStorage.setItem("intro", Intro);
      }
      window.location.reload()
      return;
    }
    return response;
  }


  export async function postUser(url: string, data: any) {
    if (
      localStorage.getItem("token") !== null &&
      localStorage.getItem("token") !== undefined
    ) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
    }
    const configUser = {
      headers: {
        "content-type": "multipart/form-data"
      },
    };
    url = url.includes("api") ? url : BASE_URL + url;
    const response : any = await axios.post(url, data, configUser);
    if(response && response.data && response.data.statusCode && response.data.statusCode=="401")
    {
      var myCookie = localStorage.getItem("setCookie");
      var Intro = localStorage.getItem("intro");
      localStorage.clear();
      if (myCookie != null) {
        localStorage.setItem("setCookie", myCookie);
      }
      if (Intro != null) {
      localStorage.setItem("intro", Intro);
      }
      window.location.reload()
      return;
    }
    return response;
  }