import React, { useEffect, useRef, useState } from 'react';
import './HomePage.css'
import { BrowserRouter, Routes, Route, redirect, Navigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog'
import AddTaskBarIcon from '../../assets/addTaskBarIcon.png'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { encData } from '../Services/Calls';


interface IProps {
  colorSchemes: any
  isDark: any
  mobileView: any
  taskInputFocus:any
  listItem:any
  showIntroScreen:any
  setTaskInputFocus:any
  addTaskToList:any
  taskInputValue:any
  handleChange:any
  handleKeyDown:any
  postTaskList:any
  setTaskInputValue:any
  setTaskSectionOpen:any
  postTask:any
  selectedProjectID:any
}


function AddTaskInput(
  {    
    colorSchemes,
    isDark,
    mobileView,
    taskInputFocus,
    listItem,
    showIntroScreen,
    setTaskInputFocus,
    addTaskToList,
    taskInputValue,
    handleChange,
    handleKeyDown,
    postTaskList,
    setTaskInputValue,
    setTaskSectionOpen,
    postTask,
    selectedProjectID
  }: IProps
) {
  const [taskInputValue1, setTaskInputValue1]:any = useState("");
  return (
    <div
                          className='addTask'

                          
                          style={{
                            width: '100%',
                            
                            background: colorSchemes.WebLeftPaneBg,
                            borderRadius: mobileView ? taskInputFocus ? '0' : '' : '',
                            borderTopLeftRadius: mobileView ? taskInputFocus ? '10' : '' : '',
                            borderTopRightRadius: mobileView ? taskInputFocus ? '10' : '' : '',
                          }}
                        >

                          {!mobileView ?
                            <img
                              style={{ width: 20, height: 20, cursor: 'pointer' }}
                              src={AddTaskBarIcon}
                            />
                            :
                            <>
                              {taskInputFocus ?
                                <>
                                  {isDark == 1 ?
                                    <img
                                      style={{ width: 20, height: 20, cursor: 'pointer'}}
                                      src={AddTaskBarIcon}
                                    />
                                    :

                                    <img
                                      style={{ width: 20, height: 20, cursor: 'pointer' }}
                                      src={AddTaskBarIcon}
                                    />
                                  }

                                </>
                                :

                                <img
                                  style={{ width: 20, height: 20, cursor: 'pointer'}}
                                  src={AddTaskBarIcon}
                                />
                              }
                            </>
                          }
                          <input
                            className={isDark == 1 ? 'addTaskInputDark' : 'addTaskInput'}
                            style={mobileView ? taskInputFocus ? { fontSize: 18, color: colorSchemes.FontBlackColor } : { fontSize: 18, color: '#fff'} : {}}
                            type='text'
                            name='name'
                            placeholder={listItem.isNote && listItem.isNote == "1" ? 'Add a Note' : 'Add a Task'}                            
                            onFocus={() => { if (mobileView && !showIntroScreen) { setTaskInputFocus(true) } }}
                            onBlur={() => { if (mobileView && !showIntroScreen) { setTaskInputFocus(false); 
                              /* addTaskToList() */
                              postTask(taskInputValue1, selectedProjectID)
                              setTimeout(() => {
                                setTaskInputValue('')
                                setTaskInputValue1("")
                              }, 200);
                              setTaskSectionOpen(false)
                             } }}
                            value={taskInputValue1}
                            onChange={(e:any) => { if (!showIntroScreen) {setTaskInputValue1(e.target.value) /* handleChange(e) */ } }}
                            autoComplete='off'
                            onKeyDown={(e) => { if (!showIntroScreen) { 
                              if (e.key === 'Enter') {
                                //addTaskToList()
                                postTask(taskInputValue1, selectedProjectID)
                                setTaskSectionOpen(false)
                                setTaskInputFocus(false);
                                setTimeout(() => {
                                  setTaskInputValue('')
                                  setTaskInputValue1("")
                                }, 200);
                                console.log('User pressed: ', e.key)
                              }/* handleKeyDown(e) */ } }}
                            maxLength={255}
                            onPaste={(e) => {
                              if (!showIntroScreen) {
                                console.log(e.clipboardData.getData('Text'));
                                let impacts = e.clipboardData.getData('Text');
                                const result: any = impacts.split("\n").map((impact, index) => (
                                  encData(`${impact.trim()}`)
                                ));

                                let tempData: any = [];
                                if (result && result.length > 1) {
                                  for (let i = 0; i < result.length; i++) {
                                    if (result[i] != "") {
                                      tempData.push(result[i]);
                                    }
                                  }
                                }

                                console.log("result45", result);
                                if (tempData.length > 1) {
                                  postTaskList(tempData);


                                  setTimeout(() => {
                                    setTaskInputValue('')
                                    setTaskInputValue1("")
                                  }, 200);
                                }
                                setTaskSectionOpen(false)
                              }
                            }
                            }
                          />
                          {taskInputValue1.length > 0 && !showIntroScreen && (
                            <div>
                              <div className='addBtn' style={mobileView ? taskInputFocus ? { color: colorSchemes.FontSemiGrey, padding: '2px 6px' } : { color: colorSchemes.FontSemiGrey, padding: '2px 6px' } : {}} onClick={() => { postTask(taskInputValue1, selectedProjectID)
                                setTaskSectionOpen(false)
                                setTaskInputFocus(false);
                                setTimeout(() => {
                                  setTaskInputValue('')
                                  setTaskInputValue1("")
                                }, 200);/* addTaskToList(); */}}>
                                Add
                              </div>
                            </div>
                          )}
                        </div>
  );
}

export default AddTaskInput;
